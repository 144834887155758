import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Button from 'components/common/button/Button';
import Markdown from 'components/common/markdown/Markdown';
import PageTitle from 'components/directus/page-title/PageTitle';
import ScholarshipWinners from 'components/directus/scholarship-winners/ScholarshipWinners';
import UniversityPartners from 'components/directus/university-partners/UniversityPartners';
import scholarshipHook from 'hooks/scholarship/scholarship.hook';
import { UniversityEdges } from 'hooks/scholarship/scholarship.interface';
import { getSolidFontAwesomeIcon } from 'utils/font-awesome/solid';
import { Props } from './Scholarship.interface';

import {
  grid,
  pageTitle,
  scholarship__content,
  scholarship__container,
  scholarship__container___sendButton,
  universityPartnersUs,
} from './Scholarship.scss';

export default ({ slug }: Props): JSX.Element => {
  const { node, winners, universities } = scholarshipHook();
  const getUniversitiesByLocation = (location: string) =>
    universities.filter((university: UniversityEdges) => university.node.location === location);

  return (
    <>
      <div className={grid}>
        <div>
          <div className={pageTitle}>
            <PageTitle slug={slug} />
          </div>

          <Markdown
            className={scholarship__content}
            source={node.content}
            container
            listItemIcon={
              <FontAwesomeIcon icon={getSolidFontAwesomeIcon('faChevronRight')} size="sm" aria-label="check icon." />
            }
          />

          <div className={scholarship__container}>
            <Button
              size="large"
              role="secondary"
              to="mailto:funding@rapidformations.co.uk"
              className={scholarship__container___sendButton}
            >
              {node.button_text}
            </Button>
          </div>

          <ScholarshipWinners winners={winners} />

          <div className={universityPartnersUs}>
            <UniversityPartners location="US" universities={getUniversitiesByLocation('US')} />
          </div>
        </div>
        <div>
          <UniversityPartners location="UK" universities={getUniversitiesByLocation('UK')} />
        </div>
      </div>
    </>
  );
};
