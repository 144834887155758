import React from 'react';
import slugify from 'react-slugify';
import Img from 'gatsby-image';

import { Props, Universities } from './UniversityPartners.interface';

import {
  university__partners___heading,
  university__partners,
  university__partner,
  university__partner__img,
  university__partners___UK,
  university__partners___US,
} from './UniversityPartners.scss';

const getPartnerLocation = (location: string) => {
  switch (location.toLowerCase()) {
    case 'uk':
      return university__partners___UK;
    case 'us':
      return university__partners___US;
    default:
      return '';
  }
};

export default ({ location, universities }: Props): JSX.Element => (
  <>
    <h3 className={university__partners___heading}>{location} Partner Universities</h3>

    <div className={`${university__partners} ${getPartnerLocation(location)}`}>
      {universities.map(({ node }: Universities) => (
        <div key={`universities-${location}-${slugify(node.name)}`} className={university__partner}>
          <Img
            fixed={node.logo.localFile.childImageSharp.fixed}
            className={university__partner__img}
            title={`${node.name} logo`}
            alt={`${node.name} logo`}
          />
        </div>
      ))}
    </div>
  </>
);
